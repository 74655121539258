.App {
  margin-left: 0px;
  margin-right: 0px;

  height: 100%;
}

.contentPanel {
  height: 80vh; /* 设置高度为视窗高度的80% */
  overflow-y: auto; /* 添加垂直滚动条 */
  width: 100%;
  top: 80px;
  position: absolute;

}
.card {
  width: 80%;
  margin: 0 auto;
}
.welcomeCard {
  background: var(--surface-50);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.welcome-first-div {
  flex: 0 0 30%;
}
.welcome-content-div {
  flex: 0 0 70%;
  display: flex;
  justify-content: space-around;
}
.welcome-content-child-div {
  width: 33%;
  background: var(--surface-100);
  margin:10px;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  display: flex;

}
.welcome-content-child-div-title {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
}

.contentTopPanel {
  height: 80px;
  top: 0px;
  position: absolute;
  text-align: left;
  place-items: center; /* 垂直水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100%;
  display: flex;
}

.topicChip {
  width: auto;
  margin:5px;
  background-color: white;
  color: black;
  cursor: pointer;
  transition: color 0.5s ease-in-out;
  padding: 10px;
  border-radius: 10px;
  user-select: none;
}
.topicChip:hover {
  background-color: var(--surface-200);
}


.brandDiv{
  place-items: center; /* 垂直水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 50px;
}

.footer {
  height: 80px;
  width: 80%;
  bottom: 20px;
  text-align: center;
  align-items: center;
  position: absolute;
}

.searchField {
  width: 80%;
}
html,body{
  height:100%;
  margin: 0; /* 去除默认边距 */

}

.leftContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* 垂直方向布局 */
  background-color: #436EA2;
  color: white;

}

.historyContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* 垂直方向布局 */
  margin-top: 40px;
}

.historyContainer-sub {
  margin: 4px; /* 例如：设置子元素之间的间距 */
  padding: 10px;
  background-color: transparent;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .3);
}

.historyContainer-newChat {
  margin: 4px; /* 例如：设置子元素之间的间距 */
  padding: 10px;
  background-color: transparent;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .3);
  cursor: pointer;
}

.historyContainer-sub:hover {
  border: 1px solid white;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  /*background-color: white;*/

}
.historyContainer-sub-active {
  border: 1px solid white;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);

}
.historyItem {
  display: grid;
  grid-template-columns: 90% 10%; /* 指定两列宽度比例 */
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  user-select: none;

}

.historyItem-left-div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* 超出部分自动隐藏并显示省略号 */
  place-items: center; /* 垂直水平居中 */
  align-items: center; /* 垂直居中 */

}


.pi-times-circle {
  color: white;
}
.footSetting {
  bottom: 50px;
  margin-top: auto;
  padding-bottom: 10px;

  /*border-top:1px solid #ccc;*/
  /*border: 1px solid #ccc;*/

}

.settingPanelFoot {
  bottom: 50px;
  margin-top: auto;
  padding: 10px;
}

.sidebarContainer {
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: column; /* 垂直方向布局 */
}
.sidebarSubItem {
  margin: 5px; /* 例如：设置子元素之间的间距 */
  text-align: center;

}
.configItem {
  display: grid;
  grid-template-columns: 25% 75%; /* 指定两列宽度比例 */
  align-items: center; /* 垂直居中 */
  cursor: pointer;
  user-select: none;
  margin-bottom: 20px;
}
.configItem-left-div {
  overflow: hidden;
  white-space: nowrap;
  place-items: center; /* 垂直水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: right;
  padding-right: 10px;

}
.configItem-right-div {
  text-align: left;
  width: 100%;
}


.securityParent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.securityParent-child {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin-bottom: 10px;
  align-items: center; /* 垂直居中 */

}
.securityParent-inner-child-left {
  width: 30%;
  height: 100%;
  text-align: right;
  padding-right:10px;
}
.securityParent-inner-child-right {
  width: 70%;
  height: 100%;
}

div .p-splitter {
  border:0;
}


/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: flex;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
  /*border-radius: 20px;*/
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #436EA2;
  border-radius: 10px;
  /*width: 2px !important;*/

}

/* 滚动条滑块悬停时 */
::-webkit-scrollbar-thumb:hover {
  background-color: cornflowerblue;
}

.statementIconDiv {
  margin-top: 20px;
  width: 100%;
  text-align: right;

}
.fileUpload input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
