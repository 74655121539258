.Translation {
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    width: 100%;

    text-align: center;
    place-items: center; /* 垂直水平居中 */
    align-items: center; /* 垂直居中 */
    /*display: flex;*/

    justify-content: center;
}

.tranCard {
    margin: 0 auto;
    top:0;
    /*background: var(--surface-500);*/
    padding: 10px;
    border-radius: 10px;
    /*margin-bottom: 1rem;*/
    display: flex;
    flex-direction: column;
    /*height: 100%;*/
}

.mainContainer{
    width: 90%;

}
/* 头部 */
.header {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    text-align: left;
}
.topCenter {
    width: 80px;
    height: 100%;
    text-align: center;
    place-items: flex-end; /* 垂直水平居中 */
    align-items: flex-end; /* 垂直居中 */
    padding: 10px;
    bottom: 0;
}

/* 内容区 */
.content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;

}

/* 左侧 */
.left {
    padding: 10px;
    width: 50%;
}



/* 右侧 */
.right {
    padding: 10px;
    width: 50%;

}
.translationContentBox {
    text-align: left;
    place-items: flex-start	; /* 垂直水平居中 */
    align-items: flex-start	; /* 垂直居中 */
    width: 100%;
    padding-top: 10px;
    height: 100%;
    background-color: var(--surface-100);
}

.translationContentSubBox {
    text-align: left;
    place-items: flex-start	; /* 垂直水平居中 */
    align-items: flex-start	; /* 垂直居中 */
    width: 100%;
    height: 100%;
}
.foot {
    padding: 20px;
}
.functionBt {
    margin-right: 100px;
    width: 200px;
}